<template>
  <section class="fade" v-if="!loading">
    <div class="inline-grid-container container-padding page-block">
      <LandingTitleBlock :title="$t('aboutFund')" line-height="45px" />
      <FundVideo
        :url="baseInfo.video_url"
        :fundPurpose="fundPurpose"
        v-if="!!baseInfo.show_video && !!baseInfo.video_url"
      />
    </div>

    <AboutFond is-page class="fond" :about="baseInfo.about"/>

    <Team  class="team"/>

  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import fundPurpose from '@/assets/img/fund_purpose.svg'
import LandingTitleBlock from '../../components/LandingTitleBlock.vue';
import AboutFond from '../mainPage/components/AboutFond/AboutFond.vue';
import BoardBlock from '../../components/BoardBlock.vue';
import TeamBlock from '../../components/TeamBlock.vue';
import Team from "@/views/mainPage/components/Team.vue";
import FundVideo from "@/views/mainPage/components/AboutFond/FundVideo.vue";

export default {
  name: 'AboutFund',

  data() {
    return {
      loading: true,
      fundPurpose,
    }
  },

  computed: {
    ...mapState(['baseInfo']),
  },

  methods: {
    ...mapActions(['getBaseInfo']),
  },

  async mounted() {
    if (!this.baseInfo) {
      await this.getBaseInfo()
    }
    this.loading = false
  },

  components: {Team, LandingTitleBlock, AboutFond, BoardBlock, TeamBlock, FundVideo },
}
</script>

<style scoped lang="scss">

.team{
  margin-bottom: 0 !important;
}
.fond {
  margin-top: 48px;
}

.slider__slide {
  width: 240px;
}

.comeback {
  margin-left: -6px;
}

.page-block {
  margin-bottom: 0;
  section {
    grid-area: 1/5/2/9;
    .title-block{
      flex-direction: row !important;
    }
  }
  width: 100%;
}

@media (max-width: 1100px) {
  .large-screen {
    display: none;
  }
  .page-block  {
    section {
      grid-area: 1/1/2/13;
    }
  }

}

@media (min-width: 1101px) {
  .small-screen {
    display: none;
  }
}

</style>
